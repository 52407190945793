import "../../../../styles/searchHome.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import {tokenLink, link} from "../../../services/variables"
import styled, { css } from "styled-components";

export default function SearchHome() {
  const [search, setSearch] = useState("");
  const [nameimg, setNameimg] = useState("");

  const navigate = useNavigate();

  const selectOpen = (e) => {
    e.preventDefault();
    navigate(`/pesquisar/${search}/`);
  };

  //background
  useEffect(() => {
    var img = ["/imagens/nome_imagem.jpg"];
    const numberMinute = parseInt(localStorage.getItem("numberMinute"));
    const idImg = parseInt(localStorage.getItem("IdImg"));

    if (idImg != null && idImg < img.length) {
      //se não existir imagem e se for menor que o array
      if (Number.isNaN(numberMinute)) {
        // não existir tempo no local Storage
        localStorage.setItem("numberMinute", Date.now());
        localStorage.setItem("IdImg", 0);
      } else if (1 < Math.floor((Date.now() - numberMinute) / 1000 / 60)) {
        // TEmpo para mudar a imagem
        const newImgId = idImg + 1;
        localStorage.setItem("numberMinute", Date.now());
        localStorage.setItem("IdImg", newImgId);
        document.getElementById(
          "back-search-main-portal"
        ).style.backgroundImage = `url(${img[newImgId]})`;
      } else {
        document.getElementById(
          "back-search-main-portal"
        ).style.backgroundImage = `url(${img[idImg]})`;
      }
    } else if (idImg == img.length) {
      //se o tamanho for igual
      document.getElementById(
        "back-search-main-portal"
      ).style.backgroundImage = `url(${img[0]})`;
      localStorage.setItem("IdImg", 0);
      localStorage.setItem("numberMinute", Date.now());
    } else {
      document.getElementById(
        "back-search-main-portal"
      ).style.backgroundImage = `url(${img[0]})`;
      localStorage.setItem("IdImg", 0);
      localStorage.setItem("numberMinute", Date.now());
    }
  }, []);

  return (
    <div className="background-global" id="back-search-main-portal">
      <div className="container-search-txt">
        <div className="container-search-txt1"></div>
      </div>
      <form className="container-search">
        <input
          type="text"
          placeholder="Buscar"
          name="search-main"
          className="input-search"
          onChange={(e) => setSearch(e.target.value)}
        />
        <button onClick={(e) => selectOpen(e)} className="link-search">
          <img
            src="/icons/search.svg"
            alt="logo buscar"
            className="svg-search"
          />
        </button>
      </form>{" "}
      <a
        style={{
          fontSize: "10px",
          position: "absolute",
          bottom: "0px",
          right: "10px",
          color: "#f5f5f5",
        }}
        href="#"
        target="_blank"
      >
        Imagem de:
      </a>
    </div>
  );
}
